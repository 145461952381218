import { useState, createContext, useContext, useEffect } from "react";

import { FaTimesCircle } from "react-icons/fa";

const ToastContext = createContext({
  toasts: [],
  addToast: () => {},
  removeToast: () => {},
});

export function useToasts() {
  return useContext(ToastContext);
}

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);
  const [counter, setCounter] = useState(0);
  const addToast = (toast) => {
    if (toast.id === null || toast.id === undefined) {
      toast.id = `unique-toast-${counter}`;
      setCounter(counter + 1);
    }
    setToasts([...toasts, toast]);
  };
  const removeToast = (toastID) => {
    setToasts(toasts.filter((t) => t.id !== toastID));
  };

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  );
}

export function ToastContainer() {
  const { toasts } = useContext(ToastContext);
  return (
    <div className="toast-container">
      <div className="toast-slider">
        <div className="toast-slide">
          {toasts
            .slice(0)
            .reverse()
            .map((toast) => (
              <Toast key={toast.id} {...toast} />
            ))}
        </div>
      </div>
    </div>
  );
}

function Toast({ id, title, text, type }) {
  const fadeIn = 400;
  const stay = 6000;
  const fadeOut = 200;

  const [deleting, setDeleting] = useState(false);
  const { removeToast } = useContext(ToastContext);

  const onDelete = () => {
    setDeleting(true);
  };
  const onRealDelete = () => {
    removeToast(id);
  };

  useEffect(() => {
    const timeout = setTimeout(onDelete, fadeIn + stay + fadeOut);
    return () => {
      clearTimeout(timeout);
    };
  }, [id]);

  const vars = {
    "--toast-fade-in": `${fadeIn}ms`,
    "--toast-fade-out": `${fadeOut}ms`,
    "--toast-stay": `${stay}ms`,
  };

  return (
    <div
      className={`toast ${type} visible ${deleting ? "deleting" : ""}`}
      style={vars}
      onAnimationEnd={deleting ? onRealDelete : null}
    >
      <span className="title">{title}</span>
      <span className="text">{text}</span>
      <div className="delete" onClick={onDelete}>
        <FaTimesCircle size={20} />
      </div>
    </div>
  );
}
