import { Link } from "react-router-dom";
import { FaAtom, FaBook, FaUser } from "react-icons/fa";

import { Services, ServiceItem } from "../components/Services";

import { Main } from "../Layout";
import { useToken } from "../hooks/useToken";

export function HomePage() {
  const [token] = useToken();
  return (
    <Main type="content">
      <HeroSection token={token} />
      {token && (
        <>
          <hr />
          <div className="text-container centered" style={{ paddingTop: 0 }}>
            <strong>Quick access links</strong>
          </div>
          <Services>
            <ServiceItem
              title="Chemistry Converter"
              summary="Upload and convert chemistry diagrams"
              link="/chemistry/upload"
              icon={<FaAtom />}
            />
            <ServiceItem
              title="Documentation"
              summary="Learn our APIs for automated workflows"
              link="/docs"
              icon={<FaBook />}
            />
            <ServiceItem
              title="User Management"
              summary="Manage your account and user details"
              link="/user"
              icon={<FaUser />}
            />
          </Services>
        </>
      )}
    </Main>
  );
}

function HeroSection({ token }) {
  return (
    <div className="hero">
      <div className="center">
        <img
          className="logo"
          src="/images/logos/progaccess_logo.png"
          alt="Progressive Access logo"
        />
        <div className="text">
          <h1 className="header">Progressive Access Live</h1>
          <h2 className="subheader">Chemistry conversion software platform</h2>
        </div>
      </div>
      <div className="rest">
        {token ? null : (
          <div className="text-container centered">
            <span className="button-joiner-left mini">
              Start becoming accessible today and
            </span>
            <Link to="/login" className="button mini">
              Login
            </Link>
            <span className="button-joiner-center mini">or</span>
            <Link to="/register" className="button mini">
              Register
            </Link>
            <span className="button-joiner-right mini">now!</span>
          </div>
        )}
      </div>
    </div>
  );
}
