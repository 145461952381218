export function Message({ type, children }) {
  return <pre className={`message ${type}`}>{children}</pre>;
}

export function ErrorMessage({ error }) {
  if (error === undefined || error === null) {
    return <></>;
  }

  return (
    <Message type="error">
      {error.message}
      {error.details && (
        <ul>
          {error.details.map((detail, i) => (
            <li key={i}>{detail}</li>
          ))}
        </ul>
      )}
    </Message>
  );
}
export function SuccessMessage({ text }) {
  if (text === undefined || text === null) {
    return <></>;
  }

  return <Message type="success">{text}</Message>;
}
