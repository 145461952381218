import { useParams } from "react-router-dom";
import useSWR from "swr";

import { GetUser, ModifyUser, Fetcher } from "../../api";
import { TextForm, ButtonForm } from "../../components/MiniForm";

import { useToasts } from "../../components/Toast";
import { useToken } from "../../hooks/useToken";

import {
  FaEnvelope,
  FaUser,
  FaCheckSquare,
  FaTimesCircle,
} from "react-icons/fa";

import { Form, EmailField, TextField } from "../../components/Form";

export function AdminUserPage() {
  const [token] = useToken();
  const { username } = useParams();

  const { data: user, mutate } = useSWR(
    token ? [GetUser, null, username, token] : null,
    Fetcher
  );

  const { addToast } = useToasts();

  function createSetAdmin(username, target) {
    return async function () {
      try {
        await ModifyUser({ admin: target }, username, token);
      } catch (e) {
        addToast({
          type: "error",
          title: "User permissions not modified",
          text: e.message,
        });
        return;
      }
      await mutate();

      addToast({
        type: "success",
        title: "User permissions modified",
        text: target
          ? `${username} was given full admin permissions`
          : `${username} was revoked admin permissions`,
      });
    };
  }

  function createSetQuota(username, variant = "main") {
    return async function (value) {
      let newQuota;
      try {
        newQuota = JSON.parse(value);
      } catch (e) {
        if (e instanceof SyntaxError) {
          addToast({
            type: "error",
            title: `User ${variant} quota not modified`,
            text: "Quota is invalid JSON",
          });
        } else {
          throw e;
        }
        return;
      }

      try {
        await ModifyUser(
          { quotas: { amounts: newQuota, variant } },
          username,
          token
        );
      } catch (e) {
        addToast({
          type: "error",
          title: `User ${variant} quota not modified`,
          text: e.message,
        });
        return;
      }
      await mutate();

      addToast({
        type: "success",
        title: `User ${variant} quota modified`,
      });
    };
  }

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Form>
        <TextField
          label="Username"
          name="username"
          iconLeft={<FaUser />}
          defaultValue={user.username}
          disabled
        />
        <EmailField
          label="Email"
          name="email"
          placeholder="Email"
          iconLeft={<FaEnvelope />}
          iconRight={
            user.email &&
            (user.email.verified ? <FaCheckSquare /> : <FaTimesCircle />)
          }
          defaultValue={user.email ? user.email.address : ""}
          disabled
        />
      </Form>

      <br />

      <ButtonForm
        label="Admin"
        text={JSON.stringify(user.admin)}
        onSubmit={createSetAdmin(user.username, !user.admin)}
      />

      <hr />

      <TextForm
        label="Quota"
        initialValue={JSON.stringify(user.quota.amounts)}
        onSubmit={createSetQuota(user.username)}
      />
      <TextForm
        label="Temporary Quota"
        initialValue={JSON.stringify(user.temporal_quota.amounts)}
        onSubmit={createSetQuota(user.username, "temporal")}
      />
      <TextForm
        label="Max Temporary Quota"
        initialValue={JSON.stringify(user.max_temporal_quota.amounts)}
        onSubmit={createSetQuota(user.username, "max_temporal")}
      />
    </>
  );
}
