import { useHistory } from "react-router-dom";

import { Main } from "../../Layout";

import { ChemHistory, Fetcher } from "../../api";

import { useToken } from "../../hooks/useToken";

import { Form, DropdownField, DropdownItem } from "../../components/Form";
import useSWR from "swr";

export function ChemistryHistoryPage({ location }) {
  const [token] = useToken();

  let history = useHistory();

  const { data: uploads, error: uploadsError } = useSWR(
    token ? [ChemHistory, null, "?sort=timestamp,desc", token] : null,
    Fetcher
  );

  const handleSubmit = async (data) => {
    history.push(`./history/${data.upload}`, {
      from: location,
      fromName: "Back to history",
    });
  };

  if (uploadsError) {
    return (
      <Main type="content">
        <div>failed to load</div>
      </Main>
    );
  }
  if (!uploads) {
    return (
      <Main type="content">
        <div>loading...</div>
      </Main>
    );
  }

  return (
    <Main type="content">
      <Form title="Chemistry Diagram History" onSubmit={handleSubmit}>
        <DropdownField
          label="Upload"
          name="upload"
          defaultValue={
            uploads.content.length > 0 ? uploads.content[0].id : null
          }
        >
          {uploads.content.map((item) => (
            <DropdownItem
              key={item.id}
              label={
                item.name
                  ? item.name + " @ " + displayTimestamp(item.timestamp)
                  : `Upload #${item.id}`
              }
              name={item.id}
            />
          ))}
        </DropdownField>
      </Form>
    </Main>
  );
}

function displayTimestamp(timestamp) {
  let date = new Date(
    Date.UTC(timestamp[0], timestamp[1] - 1, ...timestamp.slice(2, 6))
  );
  const ds = date.toLocaleDateString("en-gb", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const ts = date.toLocaleTimeString("en-gb", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${ds} ${ts}`;
}
