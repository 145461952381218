import { API } from "../api";

const AuthAPI = process.env.REACT_APP_API_AUTH;

export const Status = API(AuthAPI, "GET", "/status");
export const Register = API(AuthAPI, "POST", "/register");
export const Deregister = API(AuthAPI, "POST", "/deregister");
export const Login = API(AuthAPI, "POST", "/login");
export const Logout = API(AuthAPI, "POST", "/logout");
export const ChangePassword = API(AuthAPI, "POST", "/change-password");
export const ResetPassword = API(AuthAPI, "POST", "/reset-password");
export const ChangeEmail = API(AuthAPI, "POST", "/change-email");
export const ConfirmEmail = API(AuthAPI, "POST", "/verify-email");
export const ResendEmail = API(AuthAPI, "POST", "/resend-email");
export const UserInfo = API(AuthAPI, "GET", "/info");
export const ChangeTerms = API(AuthAPI, "POST", "/accept-terms");

export const GetUser = API(AuthAPI, "GET", "/users");
export const ModifyUser = API(AuthAPI, "PATCH", "/users");
export const DeleteUser = API(AuthAPI, "DELETE", "/users");

export const ListTokens = API(AuthAPI, "GET", "/tokens");
export const CreateToken = API(AuthAPI, "POST", "/tokens");
export const DeleteToken = API(AuthAPI, "DELETE", "/tokens");

export const RedeemCredit = API(AuthAPI, "POST", "/redeem");
export const ListCredits = API(AuthAPI, "GET", "/credits");
export const CreateCredit = API(AuthAPI, "POST", "/credits");
export const DeleteCredit = API(AuthAPI, "DELETE", "/credits");

export const GetLogs = API(AuthAPI, "GET", "/logs");
