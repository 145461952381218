import { Link } from "react-router-dom";
import useSWR from "swr";

import { useToken } from "../../hooks/useToken";
import { GetUser, Fetcher } from "../../api";

export function AdminUsersPage({ match }) {
  const [token] = useToken();
  const { data: users } = useSWR(
    token ? [GetUser, null, null, token] : null,
    Fetcher
  );

  return (
    <table style={{ width: "100%", tableLayout: "fixed" }}>
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Admin</th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users.map((user) => (
            <tr key={user.username}>
              <td>
                <Link to={`${match.path}/${user.username}`}>
                  {user.username}
                </Link>
              </td>
              <td>{user.email && user.email.address}</td>
              <td>{JSON.stringify(user.admin)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
