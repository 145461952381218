import { Switch, Route, Redirect } from "react-router-dom";

import { ChemistryUploadPage } from "./ChemistryUploadPage";
import { ChemistryHistoryPage } from "./ChemistryHistoryPage";
import { ChemistryExplorerPage } from "./ChemistryExplorerPage";
import { ChemistryInstructionsPage } from "./ChemistryInstructionsPage";

export function ChemistryPage({ match }) {
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <Redirect to={`${match.path}/upload`} />
      </Route>
      <Route
        exact
        path={`${match.path}/upload`}
        component={ChemistryUploadPage}
      />
      <Route
        exact
        path={`${match.path}/history`}
        component={ChemistryHistoryPage}
      />
      <Route
        exact
        path={`${match.path}/history/:id`}
        component={ChemistryExplorerPage}
      />
      <Route
        exact
        path={`${match.path}/instructions`}
        component={ChemistryInstructionsPage}
      />
    </Switch>
  );
}
