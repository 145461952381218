import useSWR from "swr";
import { Redirect } from "react-router-dom";

import { UserInfo, ListCredits, CreateCredit, Fetcher } from "../../api";
import { Main } from "../../Layout";

import {
  FormBox,
  TextField,
  DropdownField,
  DropdownItem,
  Reporter,
} from "../../components/Form";
import { Token } from "../../components/Token";
import { useToken } from "../../hooks/useToken";

export function AdminCreditPage() {
  const [token] = useToken();

  const { data: user } = useSWR(
    token ? [UserInfo, null, null, token] : null,
    Fetcher
  );
  const { data: credits, mutate } = useSWR(
    token ? [ListCredits, null, null, token] : null,
    Fetcher
  );

  if (user === undefined || user === null) {
    return <Main type="content" />;
  } else if (!user.admin) {
    return <Redirect to="/" />;
  }

  const handleCreateCredit = async (data) => {
    const items = data.item.split(",");
    await CreateCredit(
      {
        annotation: data.annotation,
        quotas: items.reduce(function (result, item) {
          return { ...result, [item]: Number(data.amount) };
        }, {}),
      },
      null,
      token
    );
    mutate();
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Quota</th>
            <th>Annotation</th>
            <th>Credit</th>
          </tr>
        </thead>
        <tbody>
          {credits &&
            credits.map((credit, idx) => (
              <tr key={idx}>
                <td style={{ fontFamily: "monospace" }}>
                  {JSON.stringify(credit.quotas)}
                </td>
                <td style={{ width: "100%" }}>{credit.annotation}</td>
                <td>
                  <Token token={credit.credit} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <br />
      <FormBox
        autoclear
        title="Create credit"
        submit="Create"
        onSubmit={handleCreateCredit}
        errorReport={Reporter.TOAST}
      >
        <TextField label="Annotation" name="annotation" />
        <DropdownField label="Item" name="item" defaultValue="chem">
          <DropdownItem label="ChemAPI" name="chem" />
          <DropdownItem label="ChemAPI + Downloads" name="chem,chem-download" />
        </DropdownField>
        <TextField label="Amount" name="amount" required />
      </FormBox>
    </>
  );
}
