import { useParams, Link } from "react-router-dom";

import { Main } from "../../Layout";

import { ChemHistory, Fetcher, ChemDownloadSnippet, ChemDownloadDiagram } from "../../api";

import { useToken } from "../../hooks/useToken";
import { DiagramExplorer } from "../../components/DiagramExplorer";
import { useToasts } from "../../components/Toast";

import useSWR from "swr";
import { FaArrowLeft, FaDownload } from "react-icons/fa";
import { DropdownItem } from "../../components/Form";

export function ChemistryExplorerPage({ location }) {
  const [token] = useToken();
  const { id } = useParams();

  const { addToast } = useToasts();

  const { data: diagram, mutate } = useSWR(
    token && id ? [ChemHistory, null, id, token] : null,
    Fetcher,
    {
      initialData: location.state ? location.state.diagram : null,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const downloadDiagram = async () => {
    let data;
    try {
      const resp = await ChemDownloadDiagram(null, id, token);
      data = resp.download;
      mutate({ ...diagram, downloadDiagram: true }, false);
    } catch (e) {
      console.error(e);
      addToast({
        type: "error",
        title: "Download failed",
        text: e.message,
      });
      return;
    }

    const blob = new Blob([data], { type: "application/html" });
    const url = URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = url;
    link.download = `chemapi-diagram-${id}.html`;
    link.click();
  };

  const downloadSnippet = async() => {
    let data;
    try {
      const resp = await ChemDownloadSnippet(null, id, token);
      data = resp.download;
      mutate({ ...diagram, downloadSnippet: true }, false);
    } catch (e) {
      console.error(e);
      addToast({
        type: "error",
        title: "Download failed",
        text: e.message,
      });
      return;
    }

    const blob = new Blob([data], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    var link = document.createElement("a");
    link.href = url;
    link.download = `chemapi-snippet-${id}.svg`;
    link.click();
  }

  return (
    <Main type="content">
      <div className="columns-flex">
        <div className="column">
          {location.state && location.state.from && (
            <div className="input-container">
              <div className="icon-left">
                <FaArrowLeft />
              </div>
              <Link
                className="button mini has-icon-left"
                to={location.state.from.pathname}
              >
                {location.state.fromName || "Back"}
              </Link>
            </div>
          )}
        </div>
        {diagram && (
          <div className="column">
            <div className="input-container">
              <div className="icon-left">
                <FaDownload />
              </div>
              <button
                className="button mini has-icon-left"
                onClick={downloadDiagram}
              >
                {diagram.downloadDiagram ? "Download diagram" : "Generate diagram download"}
              </button>
            </div>
          </div>
        )} 
        { diagram && (
          <div className="column">
            <div className="input-container">
              <div className="icon-left">
                <FaDownload />
              </div>
              <button
                className="button mini has-icon-left"
                onClick={downloadSnippet}
              >
                {diagram.downloadSnippet ? "Download snippet" : "Generate snippet download"}
              </button>
            </div>
          </div>
        )}
      </div>
      {diagram && (
        <DiagramExplorer
          uid={diagram.id}
          svg={diagram.svg}
          cml={diagram.annotations}
        />
      )}
    </Main>
  );
}
