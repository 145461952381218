import { Main } from "../../Layout";
import { DiagramExplorerInstructions } from "../../components/DiagramExplorer";

export function ChemistryInstructionsPage({ location }) {
  return (
    <Main type="content">
      <DiagramExplorerInstructions />
    </Main>
  );
}
