import { Article, Main, MenuLink } from "../Layout";
import { Link } from "react-router-dom";

export function TermsAndConditionsPage() {
  return (
    <Main type="content">
      <Article>
      <h1 id="progressive-live-online-subscription-agreement">Progressive Live Online Subscription Agreement</h1>
        <p>Progressive Accessibility Solutions Ltd (“P.A.S”, “we”, “us”, or “our”) provides an online subscription service that allows the user (“the user”) to access our ChemSpeech conversion software to generate accessible chemical diagrams either via an Uploader portal or via an Application Programming Interface (API), owned and delivered by us and hereinafter referred to as “ChemSpeech”.</p>
        <p>By using ChemSpeech the user agrees to be bound by the Agreement.</p>
        <p>The terms of use set out below (“terms of use”) govern the user’s subscription to ChemSpeech (“the subscription”) and includes, the privacy policy, for the subscription or its renewal. It is effective on the date we provide the user with confirmation of the subscription or the date on which the subscription is renewed, as applicable.</p>
        <h2 id="use-of-chemspeech">1. Use of <strong>ChemSpeech</strong></h2>
        <p><strong>a) Right to use.</strong> Subject to the terms of use and upon the user's full payment of the subscription fees, we grant the user the right to access and use ChemSpeech. We reserve all other rights.</p>
        <p>When subscribing to ChemSpeech:</p>
        <p>i) the user must choose the package to be included in the subscription, as described in this section;</p>
        <p>ii) the user may seek advice on the suitable package directly from P.A.S. . In such case, P.A.S. will use its best effort to suggest a suitable package type based on the information provided by the user. The user shall bear the risk for the correctness and completeness of information provided by them. Under any circumstances, P.A.S. provides no guarantees, express or implied, as to the suitability of the chosen package and hereby disclaims any and all liability related to the choice made by the user;</p>
        <p>iii) each package grants the user a specific quota and a specific set of usage-rights to access ChemSpeech via the API or the Uploader portal as described on the Progressive Live website.</p>
        <p><strong>b. Acceptable use.</strong> The user may use ChemSpeech only in accordance with the terms of use. The user may not disable, tamper with, or otherwise attempt to circumvent any billing mechanism that meters their use of ChemSpeech. The user may not rent, lease, lend, resell, transfer, their subscription or any portion thereof, to or for third parties except as expressly permitted in the terms of use.</p>
        <p><strong>c. End Users.</strong> The user controls access by End Users, and is responsible for their use of ChemSpeech in accordance with the terms of use. For example, the user will ensure End Users comply with the acceptable use policy, as set out in sub-section above.</p>
        <p><strong>e. Responsibility for the user’s accounts.</strong> The user is responsible for maintaining the confidentiality of any non-public authentication credentials associated with their use of ChemSpeech. The user must promptly notify P.A.S. about any possible misuse of their accounts or authentication credentials or any security incident related to ChemSpeech.</p>
        <p>The user must not attempt or perform any action to destroy, limit or interrupt the functionality of any hardware, software of telecommunications equipment connected to the functioning of ChemSpeech.</p>
        <p>The user fully understands and agrees that they may download or otherwise gather material or data by using ChemSpeech at their own risk and discretion and that the user is the sole responsible for any possible damage to their computer or data loss that may arise as a result of downloading such content or data.</p>
        <p><strong>f. Preview releases.</strong> P.A.S. may make additional beta versions of services available that go beyond the services provided by the subscription. Theses additional beta versions of services are provided "as-is," "with all faults," and "as-available," and are excluded from all limited warranties provided in the terms of use. These additional beta versions of services may not be covered by customer support. We may change or discontinue these additional beta versions of services at any time without notice. We also may choose not to release these additional services into general availability.</p>
        <p><strong>h. Administrator Assumption of Control.</strong> If the user uses an email address provided by an organization they are affiliated with (e.g. an employer or school) to use ChemSpeech individually, they represent that the user have authority to use that organization’s domain to sign up for a subscription in their capacity as a member of that organization. The organization, as the owner of the domain associated with the user’s email address, may assume control over and manage their use of ChemSpeech. In such a case, the user’s organization designated administrator (the user’s "admin") may (i) control and administer the user’s account, including modifying and terminating the user’s access and (ii) access and process the user’s data, including the contents of the user’s communications and files. P.A.S. may inform the user that their organization has assumed control of ChemSpeech covered by the user’s Subscription, but P.A.S. is under no obligation to provide such notice. If the user’s organization is administering the user’s use of ChemSpeech or managing the tenant associated with their subscription, direct their data subject requests and privacy inquiries to their administrator. If the user’s organization is not administering the subscription or managing such tenant, direct the user’s data subject requests and privacy inquiries to P.A.S.</p>
        <h2 id="purchasing-services">2. Purchasing services</h2>
        <p><strong>a. Available Subscription offers.</strong> The Progressive Live website provides offer details for available subscription offers, which generally can be categorized as one or a combination of the following:</p>
        <p><strong>(i) Commitment Offering.</strong> The user commits in advance to purchase a recurring quota subscription for use during a set period, e.g., one month, and to pay upfront or on a periodic basis in advance of use. With respect to the subscription, additional or other usage (for example, usage beyond your commitment quantity) may be treated as a Consumption Offering, as described below in sub-section 2.a.ii. Purchased quantities not used during the term will expire at the end of the term.</p>
        <p><strong>(ii) Consumption Offering (also called Pay-As-You-Go).</strong> The user pays based on the quota pack chosen with no upfront commitment. Payment is due upfront</p>
        <p><strong>(iii) Custom bespoke package:</strong> if none of the subscription offers detailed on our website suits the user’s needs, the user can get in touch with <a href="mailto:sales@progressiveaccess.com" class="email">sales@progressiveaccess.com</a> for a bespoke package.</p>
        <p><strong>(iv) Limited Offering.</strong> The user receive a limited quota for a limited term without charge (for example, as a trial Subscription or free account). Provisions in this agreement with respect to pricing, cancellation fees, payment, and data retention may not apply.</p>
        <p><strong>b. Pricing and payment.</strong> Payments are due and must be made according to details provided on the Progressive Live website for the subscription.</p>
        <p><strong>(ii)</strong> For Consumption Offerings, pricing is subject to change at any time.</p>
        <p><strong>c. Renewal.</strong></p>
        <p><strong>(i)</strong> Upon renewal of the user’s subscription, the renewed subscription will thereafter be governed, by the terms and conditions set forth on the Progressive Live website on the date on which the user’s subscription is renewed (the "Renewal Terms"). If the user do not agree to any the Renewal Terms, the user may decline to renew the subscription.</p>
        <p><strong>(ii)</strong> For Commitment Offerings, the user may choose to have a subscription automatically renewed or terminate upon expiration of the term. Automatic renewal is pre-selected. The user can change their selection at any time during the term. If the existing term is longer than one calendar month, we will provide the user with notice of the automatic renewal before the expiration of the term.</p>
        <p><strong>(iv)</strong> For Limited Offerings, renewal may not be permitted.</p>
        <p><strong>f. Taxes.</strong> Prices are exclusive of any taxes unless otherwise specified on the invoice as tax inclusive. The user must pay any applicable value added, goods and services, sales, gross receipts, or other transaction taxes, fees, charges or surcharges, or any regulatory cost recovery surcharges or similar amounts that are owed as a result of the use of ChemSpeech and which we are permitted to collect from the user under applicable law. We will be responsible for all taxes based on our net income, gross receipts taxes imposed in lieu of taxes on income or profits, or taxes on our property ownership.</p>
        <p>If any taxes are required to be withheld on payments the user make to us, the user may deduct such taxes from the amount owed to us and pay them to the appropriate taxing authority; provided, however, that the user promptly secure and deliver an official receipt for those withholdings and other documents we reasonably request to claim a foreign tax credit or refund. The user must ensure that any taxes withheld are minimized to the extent possible under applicable law.</p>
        <h2 id="term-termination-and-suspension">3. Term, termination, and suspension</h2>
        <p><strong>a. Agreement term and termination.</strong> This agreement will remain in effect until the expiration, termination, or renewal of the user’s subscription, whichever is earliest.</p>
        <p><strong>b. Subscription termination.</strong> The user may terminate a subscription at any time during its term; however, the user must pay all amounts due and owing before the termination is effective.</p>
        <p><strong>(i) One-Month Subscription.</strong> A subscription having a one-month term may be terminated anytime without any cancellation fee.</p>
        <p><strong>(ii) Subscriptions of more than one-month.</strong> If the user terminate a subscription to ChemSpeech within 30 days of the date on which the subscription became effective or was renewed, no refunds will be provided, and the user must pay for the initial 30 days of the subscription, but no payments will be due for the remaining portion of the terminated Subscription. If the user terminates a subscription to ChemSpeech at any other time during the term, the user must pay for the remainder of the term, and no refunds will be provided.</p>
        <p><strong>(iii) Limited Offerings.</strong> P.A.S. may terminate the user’s Subscription to a Limited Offering at any time during the term immediately without notice.</p>
        <p><strong>(iv) Other Subscriptions.</strong> For all other subscriptions, if the user terminate a subscription before the end of the Term, the must pay a fee equal to one-month’s subscription fee and they will receive a refund of any portion of the Subscription fee they have paid for the remainder of the Term; provided, however, no refunds will be provided for partially unused months.</p>
        <p><strong>c. Suspension.</strong> We may suspend the use of ChemSpeech if: (1) it is reasonably needed to prevent unauthorized access to Customer Data; (2) the user fail to respond to a claim of alleged infringement under Section 5 within a reasonable time; (3) the user do not pay amounts due under this agreement; (4) the user do not abide by the Acceptable Use Policy or violate other terms of use; or (5) for Limited Offerings, the Subscription becomes inactive from the user’s failure to access ChemSpeech as described in the details of the offer Detail. If one or more of these conditions occurs, then:</p>
        <p><strong>(i)</strong> For Limited Offerings, we may suspend the user’s use of ChemSpeech, their subscription and their account immediately without notice.</p>
        <p><strong>(ii)</strong> For all other subscriptions, we will give notice before we suspend, except where we reasonably believe we need to suspend immediately. We will give at least 30 days' notice before suspending for non-payment. If the user do not fully address the reasons for the suspension within 15 days after we suspend, we may terminate their subscription and delete their Customer Data without any retention period. We may also terminate the user’s Subscription if their use of ChemSpeech is suspended more than twice in any 12-month period.</p>
        <h2 id="warranties">4. Warranties</h2>
        <p><strong>a. Limited warranty.</strong></p>
        <p>ChemSpeech will operate during the subscription in accordance with the performance specifications set forth on the Progressive Live website. We will address issues brought forth by the user as soon as possible, generally within 48 hours. However, we cannot guarantee a turnaround time.  Our maximum liability to the user the for any incident giving rise to a claim will not exceed the amount the user paid for the subscription during one month before the incident.</p>
        <p>This limited warranty is subject to the following limitations:</p>
        <p><strong>(i)</strong> any implied warranties, guarantees or conditions not able to be disclaimed as a matter of law will last one year from the start of the limited warranty;</p>
        <p><strong>(ii)</strong> this limited warranty does not cover problems caused by accident, abuse or use of ChemSpeech in a manner inconsistent with the terms of use or our published documentation or guidance, or resulting from events beyond our reasonable control;</p>
        <p><strong>(iii)</strong> this limited warranty does not apply to problems caused by a failure to meet minimum system requirements; and</p>
        <p><strong>(iv)</strong> this limited warranty does not apply to Previews or Limited Offerings.</p>
        <p><strong>c. Disclaimer.</strong> Other than this warranty, we provide no warranties, whether express, implied, statutory, or otherwise, including warranties of merchantability or fitness for a particular purpose. These disclaimers will apply except to the extent applicable law does not permit them.</p>
        <h2 id="defense-of-claims">5. Defense of claims</h2>
        <p><strong>a. Defense.</strong></p>
        <p><strong>(i)</strong> We will defend the user against any claims made by an unaffiliated third party that ChemSpeech infringes that third party’s patent, copyright or trademark or makes unlawful use of its trade secret.</p>
        <p><strong>(ii)</strong> The user will defend us against any claims made by an unaffiliated third party that (1) any Customer Data, Customer Solution, or products, or services the user provide, directly or indirectly, in using ChemSpeech infringes the third party’s patent, copyright, or trademark or makes unlawful use of its trade secret; or (2) arises from violation of the Acceptable Use Policy.</p>
        <p><strong>b. Limitations.</strong> Our obligations in Section 5.a. will not apply to a claim or award based on: (i) any Customer Solution, Customer Data, products, modifications the user make to ChemSpeech, or services or materials the user provide or make available as part of using ChemSpeech; the user’s use of ChemSpeech after we notify the user to stop due to a third-party claim; (ii) the user’s use for the benefit of, any unaffiliated third party; or (iii) Previews or Limited Offerings provided free of charge.</p>
        <p><strong>c. Obligations.</strong> Each party must notify the other promptly of a claim under this Section. The party seeking protection must (i) give the other sole control over the defense and settlement of the claim; and (ii) give reasonable help in defending the claim. The party providing the protection will (1) reimburse the other for reasonable out-of-pocket expenses that it incurs in giving that help and (2) pay the amount of any resulting adverse final judgment or settlement. The parties’ respective rights to defense and payment of judgments (or settlement the other consents to) under this Section 5 are in lieu of any common law or statutory indemnification rights or analogous rights, and each party waives such common law or statutory rights.</p>
        <h2 id="applicable-law-and-venue">6. <strong>Applicable law and venue</strong></h2>
        <h2 id="the-terms-of-use-shall-be-governed-by-english-law.-any-dispute-arising-from-the-validity-interpretation-and-execution-of-the-terms-of-use-shall-be-submitted-to-the-competent-uk-courts.">The terms of use shall be governed by English law. Any dispute arising from the validity, interpretation and execution of the terms of use shall be submitted to the competent UK Courts.</h2>
        <p><strong>7. Intellectual Property Rights</strong></p>
        <p>The user acknowledges that ChemSpeech, including any derivative materials or works and proprietary or confidential information, are protected by trademarks, copyrights, patents, service marks, trade secrets, intellectual properties and proprietary rights owned by P.A.S.</p>
        <p>P.A.S. is the sole owner of all intellectual property rights relating to ChemSpeech and is to remain the sole owner of any intellectual property rights covering future development of ChemSpeech and related services.</p>
        <p>Apart from the limited use granted to the user by these terms of Use, the user cannot acquire any title, right or interest in the Data or any of its material or content. The user agrees not to use, collect, copy, reproduce, distribute, alter or create derivative works based upon, sell, publicly display, or otherwise exploit any content or information displayed on P.A.S.’ site for any commercial aims or to the extent of such actions constituting copyright violations, injure or otherwise violate intellectual property of other proprietary rights of the Web service, except with a prior written consent from P.A.S.</p>
        <h1 id="privacy-policy"><strong>8. Privacy Policy</strong></h1>
        <p>This privacy policy document contains types of information that is collected and recorded<br />
        by us and how we use it.</p>
        <p>If the user has additional questions or require more information about our Privacy Policy, they should not hesitate to contact us.</p>
        <p>This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they share for the purpose of subscribing to ChemSpeech. This policy is not applicable to any information collected offline or via channels other than this website.</p>
        <h2 id="data-collection"><strong>Data Collection</strong></h2>
        <p>When the user subscribes to ChemSpeech, we will ask their name, username and email address, solely for the purpose of identifying users and communicate with them via emails. Personal data can be fully removed when deleting an account. No further record will be kept. No other personal data will be collected in the App.</p>
        <p>If the user contacts us directly, we may receive additional information about them such as their phone numbers, company name, telephone number, the contents of the message and/or attachments the user may send us, and any other information the user may choose to provide.</p>
        <h2 id="use-of-analytics"><strong>Use of Analytics</strong></h2>
        <p>We do not use any Analytics or third party analytics.</p>
        <h2 id="cookies"><strong>Cookies</strong></h2>
        <p>We only use cookies that are required to enable the site and related services core functionality. Without them the site could not operate. We do not use any third party cookies.</p>
        <p><strong>GDPR Data Protection Rights</strong></p>
        <p>We would like to make sure the user is fully aware of all of their data<br />
        protection rights.</p>
        <p>Every user is entitled to the following:</p>
        <p>The right to access – The user has the right to request copies of their personal data.<br />
        The right to rectification – The user has the right to request that we correct any information the user believes is inaccurate. The user also has the right to request that we complete the information they believe is incomplete.<br />
        The right to erasure – The user has the right to request that we erase their personal data, under certain conditions.<br />
        The right to restrict processing – The user has the right to request that we restrict the processing of their personal data, under certain conditions.<br />
        The right to object to processing – The user has the right to object to our processing of their personal data, under certain conditions.<br />
        The right to data portability – The user has the right to request that we transfer the data that we have collected to another organization, or directly to them, under certain conditions.</p>
        <p>If the user makes a request, we have one month to respond to them. If the user would like to exercise any of these rights, they should please contact us.</p>
      </Article>
    </Main>
  );
}

