export function getQuotaDisplay(quota) {
  if (quotaDisplayNames[quota] !== undefined) {
    return quotaDisplayNames[quota];
  } else {
    return `${unknownQuotaDisplayName} (${quota})`;
  }
}
export function getQuotasDisplay(quotas) {
  return Object.keys(quotas)
    .map((quota) => `${quotas[quota]} ${getQuotaDisplay(quota)}`)
    .join(" + ");
}

const unknownQuotaDisplayName = "Unknown";
const quotaDisplayNames = {
  chem: "Chem API",
  "chem-download": "Chem API downloads",
};
