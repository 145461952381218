let onStatusCode = new Map();
export const addStatusCodeHandler = (code, handler) => {
  if (onStatusCode.has(code)) {
    onStatusCode.set(code, [...onStatusCode.get(code), handler]);
  } else {
    onStatusCode.set(code, [handler]);
  }
};
export const removeStatusCodeHandler = (code, handler) => {
  if (!onStatusCode.has(code)) {
    return;
  }
  onStatusCode.set(
    code,
    onStatusCode.get(code).filter((item) => item !== handler)
  );
};
const handleStatusCode = (code, body) => {
  if (!onStatusCode.has(code)) {
    return;
  }
  for (const handler of onStatusCode.get(code)) {
    handler(body);
  }
};

export class APIError extends Error {
  constructor(message) {
    message = message[0].toUpperCase() + message.slice(1);
    if (!/\p{P}$/u.test(message)) {
      message += ".";
    }
    super(message);

    this.name = "APIError";
  }
}

export const API = (api, method, path) => {
  let f = async (body = null, param = null, token = null) => {
    let headers = {
      "Content-Type": "application/json",
    };
    if (token !== undefined && token !== null) {
      headers["X-Token"] = token;
    }

    let url = `${api}/${path.replace(/^\//, "")}`;
    if (param !== null) {
      url += `/${param}`;
    }

    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });
    let result = await response.text();
    result = JSON.parse(result);

    handleStatusCode(response.status, result);

    if (!response.ok) {
      if (result.error) {
        let err = new APIError(result.error);
        if (result.error_details !== undefined) {
          err.details = result.error_details;
        }
        throw err;
      } else {
        throw new APIError(`${response.status}: ${response.statusText}`);
      }
    }

    return result.data === undefined ? result : result.data;
  };

  f.api = api;
  f.method = method;
  f.path = path;
  f.contentType = "application/json";
  return f;
};

export const Fetcher = async (method, body, param, token) => {
  return await method(body, param, token);
};
