import { FaTimesCircle } from "react-icons/fa";

export function List({ children, onCreate }) {
  return <div className="listing">{children}</div>;
}

export function ListItem({ title, content, icon, onDelete }) {
  return (
    <div className="listing-item">
      <div className="title-container">
        {icon && <div className="icon">{icon}</div>}
        {title && <h3 className="title">{title}</h3>}
      </div>
      {onDelete && (
        <div className="action" onClick={onDelete}>
          <FaTimesCircle size={24} />
        </div>
      )}
      <div className="content">{content}</div>
    </div>
  );
}
