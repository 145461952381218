import {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
  forwardRef,
} from "react";
import { FaCircleNotch } from "react-icons/fa";

import { ErrorMessage, SuccessMessage } from "./Message";
import { useToasts } from "../components/Toast";
import useMountedState from "../hooks/useMountedState";

const FormContext = createContext(null);

const Progress = {
  NONE: Symbol("none"),
  WAITING: Symbol("waiting"),
  SUCCESS: Symbol("success"),
  FAILURE: Symbol("failure"),
};

export const Reporter = {
  NONE: Symbol("none"),
  INLINE: Symbol("inline"),
  TOAST: Symbol("toast"),
  BOTH: Symbol("both"),
};

export function Form({
  title,
  children,
  submit = "Submit",
  level,
  variant,
  onChange,
  onSubmit,
  autoclear = false,
  initialSuccess,
  initialError,
  errorReport = Reporter.INLINE,
  successReport = Reporter.INLINE,
}) {
  const isMounted = useMountedState();

  const [data, setData] = useState({});
  const [defaultData, setDefaultData] = useState({});

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [progress, setProgress] = useState(Progress.NONE);

  const { addToast } = useToasts();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onSubmit) {
      setProgress(Progress.WAITING);
      try {
        const result = await onSubmit({ ...defaultData, ...data });

        if (
          successReport === Reporter.TOAST ||
          successReport === Reporter.BOTH
        ) {
          addToast({
            type: "success",
            title: `${submit} succeeded`,
            text: result,
          });
        }
        if (
          (successReport === Reporter.INLINE ||
            successReport === Reporter.BOTH) &&
          isMounted()
        ) {
          setSuccess(result);
          setError(null);
        }
        if (isMounted) {
          setProgress(Progress.SUCCESS);
        }

        if (autoclear && isMounted()) {
          setData({});
        }
      } catch (e) {
        console.error(e);
        if (errorReport === Reporter.TOAST || errorReport === Reporter.BOTH) {
          addToast({
            type: "error",
            title: `${submit} failed`,
            text: e.message,
          });
        }
        if (
          (errorReport === Reporter.INLINE || errorReport === Reporter.BOTH) &&
          isMounted()
        ) {
          setError(e);
          setSuccess(null);
        }

        if (isMounted()) {
          setProgress(Progress.FAILURE);
        }
      }
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange({ ...defaultData, ...data });
    }
  }, [data, defaultData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormContext.Provider
      value={{ data, defaultData, setData, setDefaultData }}
    >
      <form
        onSubmit={handleSubmit}
        className={`form${variant ? "-" + variant : ""}`}
      >
        {title && <span className="title">{title}</span>}
        <ErrorMessage error={error || initialError} />
        <SuccessMessage text={error ? null : success || initialSuccess} />

        {children}

        {onSubmit && (
          <div className="controls">
            <div className="input-container">
              <input
                type="submit"
                value={submit}
                className={`has-icon-left has-icon-right${
                  level ? " " + level : ""
                }`}
              />
              <div className="icon-right">
                {progress === Progress.WAITING && (
                  <FaCircleNotch className="spinner grower-child" />
                )}
              </div>
            </div>
          </div>
        )}
      </form>
    </FormContext.Provider>
  );
}

export function FormBox(props) {
  return <Form {...props} variant="box" />;
}

const Field = forwardRef(
  (
    {
      type,
      name,
      label,
      required,
      disabled,
      hidden,
      placeholder,
      defaultValue,
      iconLeft,
      iconRight,
    },
    ref
  ) => {
    const { data, setData, defaultData, setDefaultData } =
      useContext(FormContext);

    useEffect(() => {
      if (defaultData[name] !== defaultValue) {
        setDefaultData({ ...defaultData, [name]: defaultValue });
      }
    }, [name, defaultValue, defaultData, setDefaultData]);

    const handleChange = (event) => {
      setData({ ...data, [name]: event.target.value });
    };

    return (
      <div className="form-item" style={hidden ? { display: "none" } : {}}>
        {label && <label>{label}</label>}
        <div className="input-container">
          {iconLeft && <div className="icon-left">{iconLeft}</div>}
          <input
            className={`${iconLeft ? " has-icon-left" : ""}${
              iconRight ? " has-icon-right" : ""
            }`}
            ref={ref}
            type={type}
            name={name}
            required={required}
            disabled={disabled}
            placeholder={placeholder}
            value={data[name] === undefined ? defaultValue || "" : data[name]}
            onChange={handleChange}
          />
          {iconRight && <div className="icon-right">{iconRight}</div>}
        </div>
      </div>
    );
  }
);

export function HiddenField(props) {
  return <Field {...props} type="hidden" hidden />;
}

export function TextField(props) {
  return <Field {...props} type="text" />;
}

export function EmailField(props) {
  return <Field {...props} type="email" />;
}

export function PasswordField({ name, label, confirmation, ...props }) {
  const confirmRef = useRef(null);

  const nameConfirm = `${name}Confirm`;

  const { data } = useContext(FormContext);
  useEffect(() => {
    const value = data[name];
    const valueConfirm = data[nameConfirm];
    if (confirmRef.current !== null) {
      if (value === valueConfirm) {
        confirmRef.current.setCustomValidity("");
      } else {
        confirmRef.current.setCustomValidity(
          "Please enter a matching password."
        );
      }
    }
  }, [data, name, nameConfirm]);

  if (confirmation) {
    return (
      <>
        <Field {...props} name={name} label={label} type="password" />
        <Field
          {...props}
          ref={confirmRef}
          name={nameConfirm}
          label={`${label} (confirm)`}
          type="password"
        />
      </>
    );
  } else {
    return <Field {...props} name={name} label={label} type="password" />;
  }
}

export function FileField({ name, label, accept }) {
  const { data, setData } = useContext(FormContext);
  const inputRef = useRef(null);

  const handleChange = (event) => {
    setData({ ...data, [name]: inputRef.current.files[0] });
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          type="file"
          name={name}
          ref={inputRef}
          onChange={handleChange}
          accept={accept}
        />
      </div>
    </>
  );
}

export function DropdownField({ name, label, children, defaultValue }) {
  const { data, setData, defaultData, setDefaultData } =
    useContext(FormContext);

  useEffect(() => {
    if (defaultData[name] !== defaultValue) {
      setDefaultData({ ...defaultData, [name]: defaultValue });
    }
  }, [name, defaultValue, defaultData, setDefaultData]);

  const handleChange = (event) => {
    setData({ ...data, [name]: event.target.value });
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div className="input-container">
        <select
          name={name}
          value={data[name] === undefined ? defaultValue || "" : data[name]}
          onChange={handleChange}
        >
          {children}
        </select>
      </div>
    </>
  );
}

export function DropdownItem({ name, label }) {
  return <option value={name}>{label}</option>;
}

export function CheckboxField({
  name,
  label,
  required,
  disabled,
  defaultValue,
}) {
  const { data, setData, defaultData, setDefaultData } =
    useContext(FormContext);

  useEffect(() => {
    if (defaultData[name] !== defaultValue) {
      setDefaultData({ ...defaultData, [name]: defaultValue });
    }
  }, [name, defaultValue, defaultData, setDefaultData]);

  const handleChange = (event) => {
    setData({ ...data, [name]: event.target.checked });
  };

  return (
    <>
      <div className="input-container">
        <input
          type="checkbox"
          name={name}
          required={required}
          disabled={disabled}
          checked={
            data[name] === undefined ? defaultValue || false : data[name]
          }
          onChange={handleChange}
        />
        {label && <label style={{ display: "inline-block" }}>{label}</label>}
      </div>
    </>
  );
}
