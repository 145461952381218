import useSWR from "swr";

import { Fetcher, GetLogs } from "../../api";
import { useToken } from "../../hooks/useToken";

export function UserLogsPage() {
  const [token] = useToken();

  const { data: logs } = useSWR([GetLogs, null, null, token], Fetcher);

  return (
    <>
      <div className="text">
        <p>
          Logs help you understand the activity of your account, including when
          you changed your password or when you used some quota.
        </p>
      </div>
      <hr />
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {logs &&
            logs.map((log) => (
              <tr key={log.ref}>
                <td>{formatDate(new Date(log.timestamps["CreatedAt"]))}</td>
                <td>
                  {log.title}: {log.description}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

function pad(string) {
  return ("0" + string).slice(-2);
}

function formatDate(timestamp) {
  return (
    timestamp.getFullYear() +
    "-" +
    pad(timestamp.getMonth() + 1) +
    "-" +
    pad(timestamp.getDate()) +
    " " +
    pad(timestamp.getHours()) +
    ":" +
    pad(timestamp.getMinutes()) +
    ":" +
    pad(timestamp.getSeconds())
  );
}
