import { Link } from "react-router-dom";

export function Services({ children }) {
  return <div className="services">{children}</div>;
}

export function ServiceItem({
  icon,
  title,
  summary,
  button,
  link,
  linkExternal,
}) {
  return (
    <div className="service-item">
      <div className="icon">{icon}</div>
      <h3 className="title">{title}</h3>
      <p className="summary">{summary}</p>
      {linkExternal ? (
        <a href={link} className="button mini">
          {button || title}
        </a>
      ) : (
        <Link to={link} className="button mini">
          {button || title}
        </Link>
      )}
    </div>
  );
}
