import { useCallback } from "react";

const diagcess = require("diagcess");

export function DiagramExplorer({ uid, svg, cml }) {
  const diagramRef = useCallback(
    (node) => {
      if (node !== null) {
        let base = diagcess.Base.getInstance
          ? diagcess.Base.getInstance()
          : diagcess.Base;
        base.init();
      }
    },
    [uid, svg, cml] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (uid === null || uid === undefined) {
    return <></>;
  }

  // yay.
  svg = svg.replace(/<(.+?)\/>/g, "<$1></$1>");
  cml = cml.replace(/<(.+?)\/>/g, "<$1></$1>");

  return (
    <div className="navigation-container">
      <div className="navigation-item">
        <div
          className="ChemAccess-element"
          id={`ChemAccess-element-${uid}`}
          ref={diagramRef}
        >
          <div className="svg" dangerouslySetInnerHTML={{ __html: svg }} />
          <div className="cml" dangerouslySetInnerHTML={{ __html: cml }} />
        </div>
      </div>
      <div className="navigation-controls">
        <NavigationControls />
      </div>
    </div>
  );
}

export function DiagramExplorerInstructions() {
  return (
    <div className="navigation-container">
      <div className="navigation-controls">
        <NavigationControls />
      </div>
    </div>
  );
}

function NavigationControls() {
  return (
    <table>
      <thead>
        <tr>
          <th>Key</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {controls.map(({ keys, action }, i) => (
          <tr key={i}>
            <td>{keys.join(", ")}</td>
            <td>{action}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const controls = [
  { keys: ["Enter", "A"], action: "Activate keyboard driven exploration" },
  { keys: ["B"], action: "Activate menu driven exploration" },
  { keys: ["Escape"], action: "Leave exploration mode" },
  { keys: ["Cursor down"], action: "Explore next lower level" },
  { keys: ["Cursor up"], action: "Explore next upper level" },
  { keys: ["Cursor right"], action: "Explore next element on level" },
  { keys: ["Cursor left"], action: "Explore previous element on level" },
  { keys: ["X"], action: "Toggle expert mode" },
  { keys: ["W"], action: "Extra detail if available" },
  { keys: ["Space"], action: "Repeat speech" },
  { keys: ["M"], action: "Activate step magnification" },
  { keys: ["Comma"], action: "Activate direct magnification" },
  { keys: ["N"], action: "Deactivate magnification" },
  { keys: ["Z"], action: "Toggle subtitles" },
  { keys: ["C"], action: "Cycle contrast settings" },
  { keys: ["T"], action: "Monochrome colours" },
  { keys: ["L"], action: "Toggle language (if available)" },
  { keys: ["K"], action: "Kill current sound" },
  { keys: ["Y"], action: "Stop sound output" },
];
