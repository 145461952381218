import { API } from "./api";

export const APISpec = (...args) => {
  let f = API(...args);
  return async (...fargs) => {
    let spec = await f(...fargs);

    const endpoint = (api) => {
      const path = spec.paths[api.path.replace(/^\/chem\//, "/")];
      return path ? path[api.method.toLowerCase()] : null;
    };
    const lookup = (key) => {
      key = key.replace(/^#?\/?/, "");
      let current = spec;
      for (let part of key.split("/")) {
        current = current[part];
      }
      return current;
    };
    return {
      schema: (api) => {
        const e = endpoint(api);
        if (e === null) {
          return null;
        }
        let schema = e.requestBody.content[api.contentType].schema;
        if (schema["$ref"]) {
          schema = lookup(schema["$ref"]);
        }
        return schema;
      },
    };
  };
};
