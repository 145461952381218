import { useHistory } from "react-router-dom";

import { Main } from "../../Layout";
import { ConfirmEmail } from "../../api";
import { FormBox } from "../../components/Form";

export function ConfirmEmailPage({ location }) {
  const query = new URLSearchParams(location.search);
  const email = query.get("email");
  const token = query.get("token");

  let history = useHistory();

  const handleConfirmation = async () => {
    if (token) {
      await ConfirmEmail({
        email: email,
        code: token,
      });
      history.push("/user");
    }
  };

  return (
    <Main type="center">
      <FormBox
        title="Confirm email address"
        submit="Confirm"
        onSubmit={handleConfirmation}
      />
    </Main>
  );
}
