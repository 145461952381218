import useSWR from "swr";
import { FaKey, FaUserLock, FaUserCheck } from "react-icons/fa";

import { ListTokens, CreateToken, DeleteToken, Fetcher } from "../../api";
import { useToken } from "../../hooks/useToken";

import { Form, Reporter, TextField } from "../../components/Form";
import { List, ListItem } from "../../components/List";
import { Token } from "../../components/Token";

export function UserKeyPage() {
  const [token] = useToken();
  const { data: tokens, mutate } = useSWR(
    token ? [ListTokens, null, null, token] : null,
    Fetcher
  );

  const handleCreateKey = async (data) => {
    await CreateToken({ annotation: data.annotation }, null, token);
    mutate();
  };

  const makeHandleDelete = (tk) => async () => {
    await DeleteToken(null, tk, token);
    mutate();
  };

  return (
    <>
      <div className="text">
        <p>
          Keys are used to manage access to our services. There are two types of
          key: session keys and API keys - session keys provide browser access,
          such as for the page you're on right now, which API keys provide
          programmable access to all our APIs.
        </p>
        <p>
          On this page, you can manage your existing keys, create new API keys,
          or revoke any existing key.
        </p>
      </div>
      <hr />
      <List>
        {tokens &&
          tokens.map((tk) => (
            <ListItem
              key={tk.ref}
              title={
                tk.secret === token
                  ? tk.annotation + " (current)"
                  : tk.annotation
              }
              icon={
                tk.session ? (
                  tk.secret === token ? (
                    <FaUserCheck size={32} />
                  ) : (
                    <FaUserLock size={32} />
                  )
                ) : (
                  <FaKey size={32} />
                )
              }
              content={
                !tk.session && (
                  <>
                    Token: <Token token={tk.secret} />
                  </>
                )
              }
              onDelete={makeHandleDelete(tk.ref)}
            />
          ))}
      </List>
      <Form
        onSubmit={handleCreateKey}
        submit="Create Key"
        errorReport={Reporter.TOAST}
      >
        <TextField
          label="Annotation"
          name="annotation"
          placeholder="API Key Description"
        />
      </Form>
    </>
  );
}
