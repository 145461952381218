import useSWR from "swr";

import { useToken } from "../hooks/useToken";

import { Main } from "../Layout";
import { FormBox, TextField, HiddenField } from "../components/Form";

import { Fetcher, UserInfo, PaymentOptions, PaymentCheckoutURL } from "../api";
import { useParams } from "react-router-dom";

export function CheckoutPage() {
  const { id } = useParams();
  const [token] = useToken();
  const { data: user } = useSWR(
    token ? [UserInfo, null, null, token] : null,
    Fetcher
  );

  const { data: opt } = useSWR([PaymentOptions, null, id, token], Fetcher);
  if (opt === undefined) {
    return <Main></Main>;
  }

  const handleSubmit = () => {
    if (user.email === null || !user.email.verified) {
      throw new Error("User email must be verified to make a purchase.");
    }
    window.location = `${PaymentCheckoutURL}/${id}?token=${token}`;
  };

  return (
    <Main type="center">
      <FormBox
        title="Confirm your order"
        submit="Proceed to checkout"
        onSubmit={handleSubmit}
      >
        <HiddenField disabled name="id" defaultValue={id} />
        <TextField disabled name="name" label="Name" defaultValue={opt.name} />
        <TextField
          disabled
          name="cost"
          label="Cost"
          defaultValue={displayCost(opt.cost.currency, opt.cost.now)}
        />
        <TextField
          disabled
          name="quotas"
          label="Quotas"
          defaultValue={JSON.stringify(opt.quotas)}
        />
      </FormBox>
    </Main>
  );
}

export function displayCost(currency, amount) {
  return "$" + (amount / 100).toFixed(2);
}
