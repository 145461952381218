import { Article, Main, MenuLink } from "../Layout";
import { Link } from "react-router-dom";

export const DocsLinks = [
  {
    name: "Overview",
    link: "/docs",
    external: false,
  },
  {
    name: "Web Tool",
    link: "/tool",
    external: false,
  },
  {
    name: "FAQ",
    link: "/faq",
    external: false,
  },
  {
    name: "ChemAPI",
    link: "https://docs.progressiveaccess.com/",
    external: true,
  },
];

export function DocsPage() {
  return (
    <Main type="content">
      <Article>
        <h1>Documentation</h1>

        <h2>Online usage</h2>
        <p>
          For ease of use, all our APIs are available with a graphical
          user-interface online. You can find these in the top navbar menu.
        </p>
        <p>
          Individual instructions for each tool are available on the page,
          however, generally, each tool requires you to fill in your input
          parameters, which are then sent off to the API. Like using the API,
          this <strong>will consume quota</strong> - see below for more details.
        </p>

        <h2>API usage</h2>
        <p>
          To connect to the API manually,{" "}
          <Link to="/user/keys">create an API token</Link>. This token can be
          revoked at any time using the same interface.
        </p>
        <p>
          In all requests to any Progressive Access Live API, attach your API
          token in the <code>X-Token</code> header. For example, with ChemAPI:
        </p>
        <div className="highlight">
          <pre>
            {`
$ curl "https://api.progressiveaccess.com/chem/upload" \\
    --request POST \\
    --header "Content-Type: application/json" \\
    --header "Accept: application/xml" \\
    --header "X-Token: <your-api-token-here>" \\
    --data '{"inputType": "smiles", "formula": "C1CN(CCC12C3=CC=CC=C3CO2)CCCCC4=CN(C5=CC=CC=C54)C6=CC=C(C=C6)F"}'
        `.trim()}
          </pre>
        </div>
        <p>
          Remember that you <strong>have a limited quota</strong> of API
          invocations per API: see <Link to="/user/quotas">here</Link> for more
          details.
        </p>

        <h3>Quotas</h3>
        <p>
          Using each API will consume quota, of which each user has a limited
          amount. To get more quota, you can buy more or redeem credits on your
          user profile page. To avoid excess quota usage, each API should also
          provide a history, in which you can view previous invocations (both
          from the interface and the API).
        </p>
        <p>
          Every operation, using the API directly, or from the web interface
          will consume quota. Note that API failures or invalid inputs will{" "}
          <em>not</em> be counted towards your usage.
        </p>

        <h3>Individual APIs</h3>
        <p>Below, we have documentation for each individual API.</p>
        <ul>
          {DocsLinks.map((link) => (
            <MenuLink key={link.name} {...link} />
          ))}
        </ul>
      </Article>
    </Main>
  );
}


export function ToolPage() {
  return(
  <Main type="content">
    <Article>
      <h1 id="web-tool">Web Tool</h1>
      <p>Our web front end allows you to generate accessible molecules or convert your own molecules into accessible formats. You can use our interface to directly work with the molecules on site or download them in a format that allows for easy integration into your own material.</p>
      <h2 id="uploading">Uploading</h2>
      <p>To generate an accessible molecule diagram use our <a href="https://live.progressiveaccess.com/chemistry/upload"><em>Chemisty Uploader</em></a> portal. It allows you to specify a molecule in a number of formats that you can select via the Format selection box. The currently available formats are</p>
      <ul>
        <li><a href="https://en.wikipedia.org/wiki/Simplified_molecular-input_line-entry_system"><strong>Smiles</strong></a>, the simplified molecular-input line-entry system.</li>
        <li><a href="https://en.wikipedia.org/wiki/International_Chemical_Identifier"><strong>InChI</strong> and <strong>InChIKey</strong></a>, the IUPAC International Chemical Identifier.</li>
        <li><a href="https://en.wikipedia.org/wiki/Chemical_table_file#Molfile"><strong>Mol</strong></a>, a chemical file format.</li>
      </ul>
      <p>Chemical identifiers like Smile, InChI or InChI-key can be found in the knowledge cards for molecules on Wikipedia where they are often hidden behind a <code>show</code> button. Similarly they are provided by all the major chemical databases available online. Make sure that the format is correct, in particular, InChI has to be provided <strong>with the <code>InChI=</code> prefix</strong>, whereas InChI-key should be given <strong>without <code>Key</code> prefix</strong>.</p>
      <p>The mol file format can be generated with most chemical drawing systems that are commercially or freely available. It allows you to upload your own drawing of chemical molecules. This ensures that the generated accessible molecule will look exactly as you want it.</p>
      <h3 id="extra-options">Extra Options</h3>
      <p>There are a number of options available for the diagram generation that you can specify during upload:</p>
      <ul>
        <li><strong>Display explicit hydrogens</strong>: If the option is checked all hydrogen atoms will be explicitly drawn. When uploading a mol file, the specification in the mol file is reproduced faithfully, i.e., only those hydrogen atoms that are present in the original mol file will be drawn. In case the option is not checked when uploading a mol file, all explicit hydrogens will be removed.</li>
        <li><strong>Compute chemical names for all components</strong>: This will name both the molecule as well as all its components. This option can increase the time needed for producing the molecule and therefore slow down diagram generation.</li>
      </ul>
      <h2 id="exploring-molecules">Exploring Molecules</h2>
      <p>After uploading a molecule the result will be displayed on a separate webpage. This page allows you to directly work with the molecule. Use the Enter key to activate the exploration mode and browse using the arrow or cursor keys. For a list of all keyboard commands, see the key on the result page.</p>
      <p>In the majority of browsers the content you are exploring will be voiced automatically. Alternatively if a screen reader is connected all content will be voiced via the screen reader.</p>
      <p>In addition the result page offers you a button to download the result for later perusal.</p>
      <h2 id="download">Download</h2>
      <p>Once a molecule is converted you can either work with it directly and explore it in our webpage or download it for later use or integration into one of your documents. For generating downloads there are two options available: Either download a copy of the entire page or an HTML snippet to integrate into your own documents.</p>
      <p>Also see the section on <a href="#Using-Accessible-Molecules">how to use accessible molecules</a> for details.</p>
      <h2 id="history">History</h2>
      <p>The <a href="https://live.progressiveaccess.com/chemistry/history"><em>History page</em></a> is available via the Chemistry API menu. The history let’s you access previously converted Molecules. This allows you to revisit and download your already generated diagrams without having to use another credit.</p>
      <h2 id="using-accessible-molecules">Using Accessible Molecules</h2>
      <p>There are several ways to use our accessible documents. The easiest way is to use the provided exploration interface in our web site.</p>
      <p>However, you will often want to use molecules in your own material. For this you can download either the full page or an HTML snippet. When downloading the full page, you can host it on your own (e.g., on your departmental server or in an Learning Management System) and link to it from your own documents.</p>
      <p>Another easy way to integrate your molecules is to download a single HTML snippet. Use an HTML editor to put the snippet at the desired place in your document and add the <code>script</code> part of the snippet into the header of your file. You can integrate as many molecules into your document as you need. Note, that you will only need one copy of the <code>script</code> tag in your header.</p>
    </Article>
  </Main>
  )};


export function FaqPage() {
  return (
  <Main type="content">
    <Article>
      <h1 id="frequently-asked-questions">Frequently Asked Questions</h1>
      <h2 id="where-do-i-find-chemical-identifiers">Where do I find chemical identifiers?</h2>
      <p>Chemical identifiers like Smile, InChI or InChI-key can be found in the knowledge cards for molecules on Wikipedia where they are often hidden behind a <code>show</code> button. Similarly they are provided in major databases like <a href="https://pubchem.ncbi.nlm.nih.gov/">PubChem</a> or <a href="https://www.chemspider.com/">ChemSpider</a>.</p>
      <h2 id="how-do-i-generate-mol-files">How do I generate mol files?</h2>
      <p>The <a href="https://en.wikipedia.org/wiki/Chemical_table_file#Molfile">Mol</a> file format can be generated with most chemical drawing software that is both commercially available as well as most freeware or open source solutions like MarvinSketch, ChemSketch, XDrawChem or WinDrawChem.</p>
      <h2 id="how-can-i-integrate-the-accessibile-molecules-into-my-documents">How can I integrate the Accessibile Molecules into my documents?</h2>
      <p>The easiest way to integrate your molecules is to download a single HTML snippet. Use an HTML editor to put the snippet at the desired place in your document and add the script tag into the header of your file.</p>
      <p>Also have a look at our documentation on <a href="tool#using-accessible-molecules">using accessible molecules</a></p>
      <h2 id="trouble-shooting">Trouble Shooting</h2>
      <h3 id="problems-with-smiles-inchi-or-inchikey">Problems with Smiles, InChI or InChIKey</h3>
      <p>If you experience problems uploading a chemical identifier, make sure that the format of the identifier is correct. In particular, InChI has to be provided <strong>with the <code>InChI=</code> prefix</strong>, whereas InChI-key should be given <strong>without <code>Key</code> prefix</strong>.</p>
      <h3 id="problems-with-your-mol-file">Problems with your Mol File</h3>
      <p>If you experience problems uploading a mol file, ensure that the file is correctly formated and does not contain any junk. If you have edited the file by hand, make sure you have saved it in pure ASCII format.</p>
      <h3 id="cannot-download-result">Cannot Download Result</h3>
      <p>When downloading fails, ensure that you have enough credits left.</p>
    </Article>
  </Main>
  )};
