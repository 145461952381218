import { useEffect, useState } from "react";

import { FaSave } from "react-icons/fa";

export function ButtonForm({ text, label, onSubmit }) {
  return (
    <div className="form">
      <div className="input-container">
        {label && <label style={{ display: "inline-block" }}>{label}</label>}{" "}
        <button onClick={onSubmit}>{text}</button>
      </div>
    </div>
  );
}

export function TextForm({ initialValue, label, onSubmit }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function update(e) {
    setValue(e.target.value);
  }
  function submit(e) {
    e.preventDefault();
    if (value !== initialValue) {
      onSubmit(value);
    }
  }

  return (
    <form className="form" onSubmit={submit}>
      {label && <label>{label}</label>}
      <div className="input-container" style={{ width: "100%" }}>
        <textarea
          className="has-icon-right"
          rows={2}
          style={{
            fontFamily: "monospace",
            boxSizing: "border-box",
            width: "100%",
            resize: "none",
            whiteSpace: "pre",
          }}
          type="text"
          value={value}
          onChange={update}
        />
        {value !== initialValue && (
          <div className="icon-right clickable" onClick={submit}>
            <FaSave />
          </div>
        )}
      </div>
      <input
        type="submit"
        style={{ visibility: "hidden", position: "absolute" }}
      />
    </form>
  );
}
