import { FaUser } from "react-icons/fa";

import { Main } from "../../Layout";
import { ResetPassword } from "../../api";
import { FormBox, TextField } from "../../components/Form";

export function ForgotPasswordPage({ location }) {
  const handleSubmit = async (data) => {
    await ResetPassword({
      username: data.username,
    });
    return `Sent password reset to ${data.username}! Please check your email.`;
  };

  return (
    <Main type="center">
      <FormBox
        title="Send a password reset"
        submit="Send"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          name="username"
          label="Username"
          placeholder="Username"
          iconLeft={<FaUser />}
        />
      </FormBox>
    </Main>
  );
}
