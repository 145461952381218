import useSWR from "swr";
import { Link, Redirect } from "react-router-dom";
import { FaUser, FaKey, FaEnvelope, FaStickyNote } from "react-icons/fa";

import { Main } from "../../Layout";
import { Status, Register, Fetcher } from "../../api";
import { useToken } from "../../hooks/useToken";

import {
  FormBox,
  TextField,
  EmailField,
  PasswordField,
  CheckboxField,
} from "../../components/Form";

export function RegisterPage({ history }) {
  const [token] = useToken();
  const { data: status } = useSWR([Status, null, null, token], Fetcher);

  if (token) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const handleSubmit = async (data) => {
    await Register(data);
    history.replace("/login", { text: "Account creation successful" });
  };

  return (
    <>
      <Main type="center">
        <FormBox title="Register" submit="Register" onSubmit={handleSubmit}>
          <TextField
            required
            name="username"
            label="Username"
            iconLeft={<FaUser />}
            placeholder="Username"
          />
          <EmailField
            required
            name="email"
            label="Email"
            iconLeft={<FaEnvelope />}
            placeholder="Email"
          />
          <PasswordField
            required
            confirmation
            name="password"
            label="Password"
            placeholder="Password"
            iconLeft={<FaKey />}
          />
          <CheckboxField
            required
            name="terms_and_conditions"
            label={
              <div>
                <span>I accept the </span>
                <Link to={"/terms"}>terms of use</Link>
                <span> and </span>
                <Link to={"/privacy"}>privacy policy</Link>
              </div>
            }
          />
          {status && status.registration_code_required && (
            <TextField
              required
              name="registration_code"
              label="Registration code"
              iconLeft={<FaStickyNote />}
              placeholder="Registration code"
            />
          )}
        </FormBox>
      </Main>
    </>
  );
}
