import { Switch, Route, Redirect } from "react-router-dom";

import { useToken } from "../../hooks/useToken";
import { AdminCreditPage } from "./AdminCreditPage";
import { AdminUsersPage } from "./AdminUsersPage";
import { AdminUserPage } from "./AdminUserPage";

import { Main, MenuLink, InlineMenu } from "../../Layout";

export function AdminPage({ match }) {
  return (
    <Switch>
      <Main type="content">
        <h1>Admin portal</h1>
        <InlineMenu>
          <MenuLink name="Users" link={`${match.path}/users`} hoverable />
          <MenuLink name="Credits" link={`${match.path}/credits`} hoverable />
        </InlineMenu>

        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/users`} />
        </Route>
        <Route exact path={`${match.path}/users`} component={AdminUsersPage} />
        <Route
          exact
          path={`${match.path}/users/:username`}
          component={AdminUserPage}
        />
        <Route
          exact
          path={`${match.path}/credits`}
          component={AdminCreditPage}
        />
      </Main>
    </Switch>
  );
}
