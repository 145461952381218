import { Main } from "../Layout";
import { FormBox } from "../components/Form";

import useQuery from "../hooks/useQuery";
import { useHistory } from "react-router-dom";

export function CheckoutDonePage() {
  const { success, canceled } = useQuery();

  let history = useHistory();

  const handleSubmit = () => {
    history.push(`/user/quotas`);
  };

  let title = "";
  let message = "";
  if (canceled === "true") {
    title = "Order canceled";
    message = "You canceled the order, and no payment was taken.";
  } else if (success === "true") {
    title = "Order successful!";
    message =
      "Your order has gone through! Please allow a few minutes for your quota to update.";
  } else {
    title = "Order failed";
    message = "Something went wrong - please contact our team.";
  }

  return (
    <Main type="center">
      <FormBox
        title={title}
        submit="Return to user page"
        onSubmit={handleSubmit}
      >
        <p className="text">{message}</p>
      </FormBox>
    </Main>
  );
}
