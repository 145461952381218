import useSWR from "swr";
import { ChangeTerms } from "../../api";
import { useToken } from "../../hooks/useToken";
import { useHistory, Link } from "react-router-dom";
import { Main } from "../../Layout";
import { FormBox } from "../../components/Form";
import { UserInfo, Fetcher } from "../../api";

export function UserTermsPage() {
  const [token] = useToken();
  const { data: user, mutate } = useSWR(
    token ? [UserInfo, null, null, token] : token,
    Fetcher
  );
  let history = useHistory();

  if (user === undefined || user === null) {
    return <></>;
  }

  const handleChangeTerms = async () => {
    await ChangeTerms(null, null, token);
    mutate();
    history.push("/");
  };

  return (
    <Main type="center">
      <FormBox
        title="Terms and Conditions & Privacy Policy"
        submit="Accept"
        onSubmit={handleChangeTerms}
      >
        <div className="text">
          <p>
            To continue using our website you have to agree to our&nbsp;
            <Link to="/termsandconditions">Terms & Conditions and Privacy Policy</Link>
            &nbsp;by clicking
          </p>
        </div>
      </FormBox>
    </Main>
  );
}
