import useSWR from "swr";
import {
  FaEnvelope,
  FaUser,
  FaKey,
  FaCheckSquare,
  FaTimesCircle,
} from "react-icons/fa";

import {
  Deregister,
  ChangePassword,
  ChangeEmail,
  ResendEmail,
  UserInfo,
  Fetcher,
  PaymentPortalURL,
} from "../../api";

import {
  Form,
  EmailField,
  TextField,
  PasswordField,
  CheckboxField,
  Reporter,
} from "../../components/Form";
import { useToken } from "../../hooks/useToken";

export function UserDetailsPage() {
  const [token] = useToken();

  const { data: user, mutate } = useSWR(
    token ? [UserInfo, null, null, token] : null,
    Fetcher
  );

  const handleUpdatePassword = async (data) => {
    await ChangePassword({
      username: user.username,
      old_password: data.old_password,
      new_password: data.new_password,
    });
    return "Password successfully updated!";
  };

  const handleUpdateEmail = async (data) => {
    await ChangeEmail({
      username: user.username,
      password: data.password,
      email: data.email,
    });
    await mutate(data.email);
    return "Email successfully updated!";
  };

  const handleResendConfirmation = async (data) => {
    await ResendEmail(
      { password: data.password, email: data.email },
      null,
      token
    );
    return "Email confirmation resent!";
  };

  const handleDeleteAccount = async (data) => {
    if (data.username !== user.username) {
      throw new Error("Incorrect username");
    }

    await Deregister({
      username: user.username,
      password: data.password,
    });
    await mutate();
    return "Account deleted!";
  };

  const handlePaymentPortal = async (data) => {
    window.location = `${PaymentPortalURL}?token=${token}`;
  };

  if (user === null || user === undefined) {
    return <></>;
  }

  return (
    <>
      <Form
        autoclear
        submit="Update email"
        onSubmit={handleUpdateEmail}
        errorReport={Reporter.BOTH}
        successReport={Reporter.TOAST}
      >
        <TextField
          label="Username"
          name="username"
          iconLeft={<FaUser />}
          defaultValue={user.username}
          disabled
        />
        <EmailField
          label="Email"
          name="email"
          placeholder="Email"
          iconLeft={<FaEnvelope />}
          iconRight={
            user.email &&
            (user.email.verified ? <FaCheckSquare /> : <FaTimesCircle />)
          }
          disabled={user.email === null || user.email === undefined}
          defaultValue={user.email ? user.email.address : ""}
        />
        <PasswordField
          label="Password"
          name="password"
          iconLeft={<FaKey />}
          placeholder="••••••••"
          required
        />
      </Form>
      <Form
        errorReport={Reporter.TOAST}
        successReport={Reporter.TOAST}
        submit="Resend email confirmation"
        onSubmit={handleResendConfirmation}
      />

      <hr />

      <Form
        autoclear
        errorReport={Reporter.BOTH}
        successReport={Reporter.TOAST}
        title="Change password"
        submit="Change password"
        onSubmit={handleUpdatePassword}
      >
        <PasswordField
          label="Old password"
          name="old_password"
          iconLeft={<FaKey />}
          placeholder="••••••••"
          required
        />
        <PasswordField
          label="New password"
          name="new_password"
          iconLeft={<FaKey />}
          placeholder="••••••••"
          required
          confirmation
        />
      </Form>

      <hr />

      <Form
        autoclear
        title="Manage subscriptions"
        submit="Go to subscription management"
        onSubmit={handlePaymentPortal}
      />
      <div className="text">
        <p>
          You can manage your payment and billing details here, view your
          receipts and invoices, as well as cancel any subscriptions.{" "}
          <strong>Note</strong> that cancelling a subscription is irreversible,
          and immediately revokes all temporary quota allocatable to that
          subscription.
        </p>
      </div>

      <hr />

      <Form
        autoclear
        errorReport={Reporter.BOTH}
        successReport={Reporter.TOAST}
        title="Delete account"
        submit="Delete account"
        onSubmit={handleDeleteAccount}
        level="danger"
      >
        <TextField
          required
          name="username"
          label="Confirm username"
          iconLeft={<FaUser />}
          placeholder="Username"
        />
        <PasswordField
          label="Password"
          name="password"
          iconLeft={<FaKey />}
          placeholder="••••••••"
          required
        />
        <CheckboxField
          label="I understand that this action is irreversible!"
          name="confirm"
          required
        />
      </Form>
    </>
  );
}
