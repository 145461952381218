import useSWR from "swr";

import { FaAtom } from "react-icons/fa";

import { Fetcher, PaymentOptions } from "../api";
import { displayCost } from "./CheckoutPage";
import { getQuotasDisplay } from "../utils/quota";

import { Services, ServiceItem } from "../components/Services";
import { Main } from "../Layout";

export const PricingLinks = [
  {
    name: "Overview",
    link: "/pricing",
  },
  {
    name: "Subscription",
    link: "/pricing#subscription",
    external: true,
  },
  {
    name: "Packages",
    link: "/pricing#packages",
    external: true,
  },
];

export function PricingPage() {
  return (
    <Main type="content">
      <h1>Pricing</h1>
      <div className="text">
        <p>
          We offer a range of plans for access to our APIs! We have something
          for everyone, regardless of your use case.
        </p>
      </div>

      <Pricing />
    </Main>
  );
}

export function Pricing() {
  const { data: paymentOptions } = useSWR(
    [PaymentOptions, null, null, null],
    Fetcher
  );

  return (
    <>
      {paymentOptions && paymentOptions.subscriptions && (
        <>
          <h2 id="subscription">Get a recurring quota subscription</h2>
          <Services>
            {paymentOptions.subscriptions.map((opt) => (
              <ServiceItem
                key={opt.id}
                title={opt.name}
                summary={getQuotasDisplay(opt.quotas)}
                button={
                  opt.cost.was ? (
                    <span>
                      <s>{displayCost(opt.cost.currency, opt.cost.was)}</s>{" "}
                      {displayCost(opt.cost.currency, opt.cost.now)}
                    </span>
                  ) : (
                    <span>{displayCost(opt.cost.currency, opt.cost.now)}</span>
                  )
                }
                link={`/checkout/${opt.id}`}
                icon={<FaAtom />}
              />
            ))}
          </Services>
          <br />
        </>
      )}
      {paymentOptions && paymentOptions.packs && (
        <>
          <h2 id="packages">Get a quota pack</h2>
          <Services>
            {paymentOptions.packs.map((opt) => (
              <ServiceItem
                key={opt.id}
                title={opt.name}
                summary={getQuotasDisplay(opt.quotas)}
                button={
                  opt.cost.was ? (
                    <span>
                      <s>{displayCost(opt.cost.currency, opt.cost.was)}</s>{" "}
                      {displayCost(opt.cost.currency, opt.cost.now)}
                    </span>
                  ) : (
                    <span>{displayCost(opt.cost.currency, opt.cost.now)}</span>
                  )
                }
                link={`/checkout/${opt.id}`}
                icon={<FaAtom />}
              />
            ))}
          </Services>
          <br />
        </>
      )}

      {paymentOptions &&
        (paymentOptions.packs || paymentOptions.subscriptions) && (
          <>
            <h2 id="others">Something else?</h2>
            <p>
              Nothing here suit your needs? Get in contact with{" "}
              <a href="mailto:sales@progressiveaccess.com">
                sales@progressiveaccess.com
              </a>{" "}
              for a custom bespoke package.
            </p>
          </>
        )}
    </>
  );
}
