import { Link } from "react-router-dom";
import useSWR from "swr";

import { UserInfo, RedeemCredit, Fetcher } from "../../api";
import { useToken } from "../../hooks/useToken";

import { FormBox, Reporter, TextField } from "../../components/Form";
import { Pricing } from "../PricingPage";
import { getQuotaDisplay } from "../../utils/quota";

export function UserQuotaPage() {
  const [token] = useToken();
  const { data: user, mutate } = useSWR(
    token ? [UserInfo, null, null, token] : token,
    Fetcher
  );

  if (user === undefined || user === null) {
    return <></>;
  }

  const handleRedeemCredit = async (data) => {
    await RedeemCredit(data, null, token);
    mutate();
  };

  let quotaKeys = new Set();
  Object.keys(user.quota.amounts).forEach((key) => quotaKeys.add(key));
  Object.keys(user.temporal_quota.amounts).forEach((key) => quotaKeys.add(key));

  return (
    <>
      <div className="text">
        <p>
          Quotas limit your access to APIs and services provided by Progressive
          Access. You can find out more on our dedicated{" "}
          <Link to="/docs">docs page</Link>, or see your individual quotas
          displayed below.
        </p>
        <p>
          Temporary quotas are exactly like normal quotas, except they may
          expire or rollover as part of a subscription.
        </p>
      </div>
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: "10em" }}>Quota</th>
            <th style={{ width: "10em" }}>Temporary Quota</th>
          </tr>
        </thead>
        <tbody>
          {[...quotaKeys].map((key) => (
            <tr key={key}>
              <td>{getQuotaDisplay(key)}</td>
              <td>{user.quota.amounts[key]}</td>
              <td>{user.temporal_quota.amounts[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pricing />

      <h2>Redeem coupon</h2>
      <FormBox
        title="Coupon"
        submit="Redeem"
        onSubmit={handleRedeemCredit}
        errorReport={Reporter.TOAST}
        successReport={Reporter.TOAST}
      >
        <TextField label="Credit" name="credit" />
      </FormBox>
    </>
  );
}
