import { Redirect, Link } from "react-router-dom";
import { FaUser, FaKey } from "react-icons/fa";

import { Main } from "../../Layout";
import { Login } from "../../api";
import { useToken } from "../../hooks/useToken";

import { FormBox, TextField, PasswordField } from "../../components/Form";

export function LoginPage({ location }) {
  const [token, setToken] = useToken();

  if (token !== undefined && token !== null) {
    if (location.state === undefined || location.state.from === undefined) {
      return <Redirect to={{ pathname: "/" }} />;
    } else {
      return <Redirect to={{ pathname: location.state.from.pathname }} />;
    }
  }

  const handleSubmit = async (data) => {
    const resp = await Login({
      ...data,
      annotation: `Browser ${navigator.userAgent}`,
    });
    setToken(resp.secret);
  };

  return (
    <Main type="center">
      <FormBox
        title="Login"
        submit="Login"
        onSubmit={handleSubmit}
        initialSuccess={location.state ? location.state.text : null}
      >
        <TextField
          required
          name="username"
          label="Username"
          iconLeft={<FaUser />}
          placeholder="Username"
        />
        <PasswordField
          required
          name="password"
          label="Password"
          iconLeft={<FaKey />}
          placeholder="Password"
        />
        <Link to="/forgot">Forgot password</Link>
      </FormBox>
    </Main>
  );
}
