import { useState, useEffect } from "react";

export function Token({ token }) {
  const [copied, setCopied] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    await navigator.clipboard.writeText(token);
    setCopied(true);
  };
  useEffect(() => {
    if (!copied) return;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <span className={`token${copied ? " copied" : ""}`} onClick={handleClick}>
      {token}
    </span>
  );
}
