import { useCallback, useState, useEffect, useMemo } from "react";

export default function useLocalStorage(key, initialValue) {
  // the "initial value" based on both provided parameters
  const actualInitialValue = useMemo(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState(actualInitialValue);
  useEffect(() => {
    // if the provided parameters change, we should attempt to re-read the
    // value from the store
    setStoredValue(actualInitialValue);
  }, [actualInitialValue]);

  useEffect(() => {
    // on a local storage event, update the value
    const checkLocalStorage = (e) => {
      if (e.storageArea === window.localStorage) {
        if (key === e.key && e.newValue) {
          setStoredValue(JSON.parse(e.newValue));
        }
      }
    };

    window.addEventListener("storage", checkLocalStorage);
    return () => {
      window.removeEventListener("storage", checkLocalStorage);
    };
  }, [key]);

  const setValue = useCallback(
    (value) => {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    },
    [key, storedValue]
  );
  return [storedValue, setValue];
}
