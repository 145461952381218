import { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useToken } from "../../hooks/useToken";
import { Main } from "../../Layout";
import { Logout } from "../../api";

export function LogoutPage({ location }) {
  const [token, setToken] = useToken();

  useEffect(() => {
    async function logout() {
      try {
        await Logout(null, null, token);
      } catch (e) {
        console.error(`Logout failed: ${e}`);
      }
      setToken(null);
    }
    if (token) {
      logout();
    }
  }, [token, setToken]);

  if (token) {
    return <Main />;
  } else if (location.state && location.state.from) {
    return <Redirect to={location.state.from.pathname} />;
  } else {
    return <Redirect to="/login" />;
  }
}
