import { Switch, Route, Redirect } from "react-router-dom";

import { UserDetailsPage } from "./UserDetailsPage";
import { UserQuotaPage } from "./UserQuotaPage";
import { UserKeyPage } from "./UserKeyPage";
import { UserLogsPage } from "./UserLogsPage";

import { Main, MenuLink, InlineMenu } from "../../Layout";

export function UserPage({ match }) {
  return (
    <Switch>
      <Main type="content">
        <h1>User Details</h1>
        <InlineMenu>
          <MenuLink name="Details" link={`${match.path}/details`} hoverable />
          <MenuLink name="Quotas" link={`${match.path}/quotas`} hoverable />
          <MenuLink name="Keys" link={`${match.path}/keys`} hoverable />
          <MenuLink name="Logs" link={`${match.path}/logs`} hoverable />
        </InlineMenu>
        <Route exact path={`${match.path}`}>
          <Redirect to={`${match.path}/details`} />
        </Route>
        <Route
          exact
          path={`${match.path}/details`}
          component={UserDetailsPage}
        />
        <Route exact path={`${match.path}/keys`} component={UserKeyPage} />
        <Route exact path={`${match.path}/quotas`} component={UserQuotaPage} />
        <Route exact path={`${match.path}/logs`} component={UserLogsPage} />
      </Main>
    </Switch>
  );
}
