import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSWR from "swr";

import { Main, Text } from "../../Layout";

import { ChemUpload, Fetcher, ChemSpec } from "../../api";

import { useToken } from "../../hooks/useToken";

import {
  Form,
  DropdownField,
  DropdownItem,
  TextField,
  FileField,
  Reporter,
  CheckboxField,
} from "../../components/Form";
import { getOptionDefault, getOptionDisplay } from "../../utils/options";

export function ChemistryUploadPage({ location }) {
  const [format, setFormat] = useState(null);
  const [token] = useToken();

  let history = useHistory();

  const { data: chemSpec } = useSWR([ChemSpec], Fetcher);
  const uploadSchema = chemSpec ? chemSpec.schema(ChemUpload) : null;

  const extraOptions = uploadSchema
    ? Object.keys(uploadSchema.properties)
        .filter((key) => !["formula", "inputType"].includes(key))
        .map((k) => formExtraOption(k, uploadSchema.properties[k]))
        .filter((k) => k !== null)
    : null;

  const handleChange = (data) => {
    setFormat(data["format"]);
  };
  const handleSubmit = async (data) => {
    let input;
    if (data["text-input"]) {
      input = data["text-input"];
    }
    if (data["file-input"]) {
      input = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data["file-input"]);
        reader.onload = () => resolve(reader.result.replace(/^.*?,/, ""));
        reader.onerror = (error) => reject(error);
      });
    }
    if (input === undefined) {
      console.error("no input provided");
    }

    let submission = data;
    delete submission["text-input"];
    delete submission["file-input"];
    delete submission["format"];
    const resp = await ChemUpload(
      {
        ...submission,
        id: "test",
        formula: input,
        inputType: format,
      },
      null,
      token
    );
    history.push(`./history/${resp.id}`, {
      diagram: resp,
      from: location,
      fromName: "Back to upload",
    });
  };

  return (
    <Main type="content">
      <h1>Chemistry Uploader</h1>
      <Form
        submit="Upload"
        onChange={handleChange}
        onSubmit={handleSubmit}
        errorReport={Reporter.BOTH}
      >
        <DropdownField label="Format" name="format" defaultValue="smiles">
          <DropdownItem label="Smiles" name="smiles" />
          <DropdownItem label="InChi" name="inchi" />
          <DropdownItem label="InChi Key" name="inchikey" />
          <DropdownItem label="Mol File" name="mol" />
        </DropdownField>
        {formFormula(format)}
        {extraOptions && (
          <span>
            <strong>Extra options</strong>{" "}
            <em>(may slow down diagram generation)</em>
          </span>
        )}
        {extraOptions}
      </Form>

      <h2>Instructions</h2>
      <Text>
        <ol>
          <li>Select an input format</li>
          <li>
            Click submit (be aware that this operation consumes ChemAPI quota)
          </li>
          <li>Explore the generated diagram!</li>
        </ol>
      </Text>
      <Text>
        See <Link to="./instructions">the standalone instructions</Link> to see
        how to navigate the generated diagrams.
      </Text>
    </Main>
  );
}

function formFormula(format) {
  switch (format) {
    case "smiles":
      return <TextField label="SMILES Formula" name="text-input" />;
    case "inchi":
      return <TextField label="InChi Formula" name="text-input" />;
    case "inchikey":
      return <TextField label="InChiKey Formula" name="text-input" />;
    case "mol":
      return (
        <FileField
          label="Mol File"
          name="file-input"
          accept=".mol,chemical/x-mdl-molfile"
        />
      );
    default:
      return null;
  }
}

function formExtraOption(k, { type }) {
  const display = getOptionDisplay(k);
  if (display === null) {
    return null;
  }

  switch (type) {
    case "boolean":
      return (
        <CheckboxField
          key={k}
          name={k}
          label={display}
          defaultValue={getOptionDefault(k) || false}
        />
      );
    default:
      return (
        <TextField
          key={k}
          name={k}
          label={display}
          defaultValue={getOptionDefault(k) || ""}
        />
      );
  }
}
