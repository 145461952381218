import { useEffect } from "react";
import useSWR from "swr";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { TokenContext } from "./hooks/useToken";
import useLocalStorage from "./hooks/useLocalStorage";
import {
  addStatusCodeHandler,
  removeStatusCodeHandler,
  Fetcher,
  UserInfo,
} from "./api";

import Layout, { Main } from "./Layout";

import * as pages from "./pages";

export default function App() {
  const [token, setToken] = useLocalStorage("api-token");
  const { data: user } = useSWR(
    token ? [UserInfo, null, null, token] : null,
    Fetcher
  );

  useEffect(() => {
    const handler = (body) => {
      setToken(null);
    };

    addStatusCodeHandler(401, handler);
    return () => {
      removeStatusCodeHandler(401, handler);
    };
  }, [setToken]);

  let menu = [
    {
      name: "Chemistry API",
      link: "/chemistry/upload",
      inPrimary: true,
      submenu: [
        {
          name: "Converter",
          link: "/chemistry/upload",
        },
        {
          name: "History",
          link: "/chemistry/history",
        },
      ],
    },
    {
      name: "Pricing",
      link: "/pricing",
      inPrimary: true,
      inFooter: true,
      submenu: pages.PricingLinks,
    },
    {
      name: "Documentation",
      link: "/docs",
      inPrimary: true,
      inFooter: true,
      submenu: pages.DocsLinks,
    },
    {
      name: "Terms & Conditions",
      link: "/termsandconditions",
      inFooter: true,
    },
  ];

  if (token) {
    if (user && user.admin) {
      menu.push({
        name: "Admin",
        link: "/admin",
        inSecondary: true,
        submenu: [
          { name: "Users", link: "/admin/users" },
          { name: "Credits", link: "/admin/credits" },
        ],
      });
    }
    menu.push({
      name: "User",
      link: "/user",
      inSecondary: true,
      submenu: [
        { name: "Details", link: "/user/details" },
        { name: "Quotas", link: "/user/quotas" },
        { name: "Keys", link: "/user/keys" },
        { name: "Logs", link: "/user/logs" },
      ],
    });
    menu.push({ name: "Logout", link: "/logout", inSecondary: true });
  } else {
    menu.push({ name: "Login", link: "/login", inSecondary: true });
    menu.push({ name: "Register", link: "/register", inSecondary: true });
  }
  return (
    <TokenContext.Provider value={[token, setToken]}>
      <Router>
        <Layout menu={menu}>
          <Switch>
            <Route exact path="/" component={pages.HomePage} />
            <Route exact path="/docs" component={pages.DocsPage} />
            <Route exact path="/termsandconditions" component={pages.TermsAndConditionsPage} />
            <Route exact path="/faq" component={pages.FaqPage} />
            <Route exact path="/tool" component={pages.ToolPage} />
            <Route exact path="/pricing" component={pages.PricingPage} />

            <Route exact path="/register" component={pages.RegisterPage} />
            <Route exact path="/login" component={pages.LoginPage} />
            <Route exact path="/logout" component={pages.LogoutPage} />
            <Route exact path="/verify" component={pages.ConfirmEmailPage} />
            <Route exact path="/reset" component={pages.ResetPasswordPage} />
            <Route exact path="/forgot" component={pages.ForgotPasswordPage} />

            <PrivateRoute
              exact
              path="/checkout/done"
              component={pages.CheckoutDonePage}
              token={token}
              user={user}
            />
            <PrivateRoute
              exact
              path="/checkout/:id"
              component={pages.CheckoutPage}
              token={token}
              user={user}
            />

            <PrivateRoute
              path="/chemistry"
              component={pages.ChemistryPage}
              token={token}
              user={user}
            />
            <PrivateRoute
              path="/admin"
              component={pages.AdminPage}
              token={token}
              user={user}
            />
            <PrivateRoute
              path="/user"
              component={pages.UserPage}
              token={token}
              user={user}
            />
            <PrivateRoute
              path="/accept-terms"
              component={pages.UserTermsPage}
              token={token}
            />
            <Route>
              <Main type="content">
                <h1>Oops! Page not found!</h1>
              </Main>
            </Route>
          </Switch>
        </Layout>
      </Router>
    </TokenContext.Provider>
  );
}

function PrivateRoute({
  token,
  user,
  component: Component,
  children,
  render,
  ...rest
}) {
  if (children instanceof Function) {
    return (
      <Route
        {...rest}
        children={({ match, ...childrenRest }) =>
          children({ match: token ? match : null, ...childrenRest })
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!token) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        if (token && user && !user.terms_and_conditions) {
          return (
            <Redirect
              to={{
                pathname: "/accept-terms",
                state: { from: props.location },
              }}
            />
          );
        }
        if (render) {
          return render(props);
        }
        if (children) {
          return children;
        }
        if (Component) {
          return <Component {...props} />;
        }
      }}
    />
  );
}
