import { useHistory } from "react-router-dom";
import { FaKey } from "react-icons/fa";

import { Main } from "../../Layout";
import { ChangePassword } from "../../api";
import { FormBox, PasswordField } from "../../components/Form";

export function ResetPasswordPage({ location }) {
  const query = new URLSearchParams(location.search);
  const username = query.get("username");
  const code = query.get("code");

  let history = useHistory();

  const handleSubmit = async (data) => {
    await ChangePassword({
      username: username,
      reset_password_code: code,
      new_password: data.password,
    });
    history.push("/user");
  };

  return (
    <Main type="center">
      <FormBox title="Reset password" submit="Reset" onSubmit={handleSubmit}>
        <PasswordField
          required
          confirmation
          name="password"
          label="Password"
          placeholder="Password"
          iconLeft={<FaKey />}
        />
      </FormBox>
    </Main>
  );
}
