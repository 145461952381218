import { useState, useRef } from "react";
import { Route, Link, useLocation } from "react-router-dom";
import { ToastContainer, ToastProvider } from "./components/Toast";

export default function Layout({ menu, children }) {
  return (
    <>
      <Header
        menuLeft={menu.filter((item) => item.inPrimary)}
        menuRight={menu.filter((item) => item.inSecondary)}
      />
      <ToastProvider>
        <MainWrapper>
          <ToastContainer />
          {children}
        </MainWrapper>
      </ToastProvider>
      <Footer menu={menu.filter((item) => item.inFooter)} />
    </>
  );
}

export function MainWrapper({ children }) {
  return <main>{children}</main>;
}

export function Main({ children, type = "" }) {
  const fullType = type === "" ? "main" : `main-${type}`;
  return <div className={fullType}>{children}</div>;
}

export function Article({ children }) {
  return <article className="text">{children}</article>;
}

export function Text({ children }) {
  return <div className="text">{children}</div>;
}

function Header({ menuLeft, menuRight }) {
  const location = useLocation();

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const hamburgerMenu = useRef(null);

  return (
    <header>
      <div className="bar">
        <nav>
          <img
            className="hamburger"
            src="/images/theme/hamburger.svg"
            alt=""
            onClick={toggleSidebar}
          />

          <div className="info">
            <img src="/images/logos/mini-logo.svg" alt="" />
            <Link to={{ pathname: "/", state: { from: location } }}>
              Progressive Live
            </Link>
          </div>
          <ul className="links desktop-links desktop-links-left" role="menubar">
            {menuLeft.map((item) => (
              <MenuLinkMulti
                key={item.name}
                {...item}
                hoverable
                from={location}
              />
            ))}
          </ul>
          <ul className="links desktop-links desktop-links-right">
            {menuRight.map((item) => (
              <MenuLinkMulti
                key={item.name}
                {...item}
                hoverable
                from={location}
              />
            ))}
          </ul>
        </nav>
      </div>

      <div className="sidebar">
        <div
          ref={hamburgerMenu}
          className={`hamburger-menu${sidebarVisible ? " visible" : " "}`}
          aria-hidden="true"
          onClick={(event) => {
            if (event.target === hamburgerMenu.current) toggleSidebar();
          }}
        >
          <ul className="links mobile-links" role="menubar">
            {menuLeft.map((item) => (
              <MenuLink key={item.name} {...item} from={location.pathname} />
            ))}
            {menuRight.map((item) => (
              <MenuLink key={item.name} {...item} />
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
}

function Footer({ menu }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <nav>
        <ul className="links">
          {menu.map((item) => (
            <MenuLink key={item.name} {...item} hoverable />
          ))}
        </ul>
      </nav>
      <p className="notice">
        <span>Copyright &copy;</span>&nbsp;
        <span>2015</span>&ndash;
        <span>{currentYear}</span>&nbsp;
        <span>Progressive Accessibility Solutions Ltd.</span>
      </p>
    </footer>
  );
}

function MenuLinkMulti({ submenu, hoverable, from, ...props }) {
  if (submenu === undefined) {
    return <MenuLink hoverable={hoverable} {...props} from={from} />;
  }

  const hoverMenu = (
    <div className="links-hover-menu">
      <ul className="links-hover-menu-links" role="menu">
        {submenu.map((sub) => (
          <MenuLink key={sub.name} hoverable={hoverable} {...sub} from={from} />
        ))}
      </ul>
    </div>
  );

  return (
    <MenuLink hoverable={hoverable} {...props} from={from}>
      {hoverMenu}
    </MenuLink>
  );
}

export function InlineMenu({ children }) {
  return (
    <div className="inline-links">
      <ul className="links">{children}</ul>
    </div>
  );
}

export function MenuLink({ name, link, from, external, hoverable, children }) {
  if (external) {
    return (
      <li className="link" role="menuitem">
        <a href={link} className={hoverable ? "hoverable" : ""}>
          {name}
        </a>
        {children}
      </li>
    );
  } else {
    return (
      <Route
        exact
        path={link}
        children={({ match }) => (
          <li className={`link ${match ? "active" : ""}`}
              role="menuitem">
            <Link
              to={{ pathname: link, state: { from } }}
              className={hoverable ? "hoverable" : ""}
            >
              {name}
            </Link>
            {children}
          </li>
        )}
      />
    );
  }
}
