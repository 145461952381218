export function getOptionDisplay(opt) {
  if (optionDisplayNames[opt] !== undefined) {
    return optionDisplayNames[opt];
  } else {
    return opt.charAt(0).toUpperCase() + opt.slice(1);
  }
}
export function getOptionDefault(opt) {
  if (optionDefaults[opt] !== undefined) {
    return optionDefaults[opt];
  } else {
    return null;
  }
}

const optionDisplayNames = {
  embedMessages: null,

  addExpert: "Compute chemical names for all components",

  explicitHydrogens: "Display explicit hydrogens",

  // locales: ???,
  locales: null,
};
const optionDefaults = {
  addExpert: true,
  explicitHydrogens: false
};
